export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/list/Users.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
      permissions: ['view user'],
    },
  },
  {
    path: '/users/invite',
    name: 'user-invite',
    component: () => import('@/views/users/invite/Invite.vue'),
    meta: {
      pageTitle: 'Invite user',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Invite',
          active: true,
        },
      ],
      permissions: ['create user'],
    },
  },
  {
    path: '/users/:userId/edit',
    name: 'user-edit',
    props: true,
    component: () => import('@/views/users/edit-company-user/UserEdit.vue'),
    meta: {
      pageTitle: 'Edit user',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      permissions: ['edit user'],
    },
  },
  {
    path: '/user/edit',
    name: 'account-edit',
    props: true,
    component: () => import('@/views/users/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit your profile',
      breadcrumb: [
        {
          text: 'Users',
          to: '/users',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      permissions: ['edit user'],
    },
  },
]

import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  isAuthenticated,
} from '@/auth/sanctum'
import companies from './routes/companies'
import declarations from './routes/declarations'
import invoicing from './routes/invoicing'
import reports from './routes/reports'
import shipments from './routes/shipments'
import parcels from './routes/parcels'
import carriers from './routes/carriers'
import users from './routes/users'
import warehouses from './routes/warehouses'
import specialcommodities from './routes/specialcommodities'
import customsLog from '@/router/routes/customs_log'
import event from './routes/event'
import permissions from '@/auth/permissions'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...companies,
    ...declarations,
    ...invoicing,
    ...shipments,
    ...parcels,
    ...carriers,
    ...specialcommodities,
    ...reports,
    ...users,
    ...warehouses,
    ...customsLog,
    ...event,
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Index.vue'),
    },
    {
      path: '/authorizations',
      name: 'authorizations',
      component: () => import('@/views/authorizations/Authorizations.vue'),
      meta: {
        pageTitle: 'Authorizations',
        breadcrumb: [
          {
            text: 'Authorizations',
            active: true,
          },
        ],
      },
    },
    {
      path: '/accept-invitation',
      name: 'accept-invitation',
      component: () => import('@/views/users/invite/Accept.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/Auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/Auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// GOOD
router.beforeEach((to, from, next) => {
  const allowedPages = ['login', 'forgot-password', 'register', 'reset-password', 'accept-invitation']
  if (allowedPages.includes(to.name) === false && !isAuthenticated()) {
    next({ name: 'login' })
  } else if (to.name !== 'accept-invitation' && allowedPages.includes(to.name) === true && isAuthenticated()) {
    next('/')
  } else {
    if (to.meta.permissions) {
      const hasPermission = to.meta.permissions.some(permission => permissions.hasPermission(permission))
      if (!hasPermission) {
        next({ path: '/403' })
        return
      }
    }
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router

export default [
  {
    path: '/warehouses',
    name: 'warehouses',
    component: () => import('@/views/warehouses/list/List.vue'),
    meta: {
      pageTitle: 'Warehouses',
      permissions: ['view all warehouses'],
    },
  },
  {
    path: '/warehouses/add',
    name: 'add-warehouses',
    component: () => import('@/views/warehouses/add/Add.vue'),
    meta: {
      pageTitle: 'Add Warehouses',
      breadcrumb: [
        {
          text: 'Warehouses',
          to: '/warehouses',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
      permissions: ['create warehouse'],
    },
  },
  {
    path: '/warehouses/edit/:warehouseId',
    name: 'edit-warehouses',
    props: true,
    component: () => import('@/views/warehouses/edit/Edit.vue'),
    meta: {
      pageTitle: 'Edit Warehouses',
      breadcrumb: [
        {
          text: 'Warehouses',
          to: '/warehouses',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      permissions: ['edit warehouse'],
    },
  },
]
